import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from "axios"
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.prototype.axios = axios
axios.defaults.baseURL = 'http://127.0.0.1'
Vue.config.productionTip = false

Vue.use(ElementUI);

router.beforeEach((to, from, next)=>{
  console.log(to)
  document.title = '小鸡鸡'
  next()
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
