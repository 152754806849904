import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const main = () => import("../views/Main.vue")    //懒加载
const list = () => import("../components/list.vue")

const routes = [
  {
    path: '/',
    component: main,
    // redirect:'/kuilld',//重定向
    // children: [
    //   // {
    //   //   path: 'kuilld',
    //   //   name:'Kuilld',
    //   //   component: kuilld
    //   // }
    // ]
  },
  {
    path: '/list',
    name: 'list',
    component: list,
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

//使用push的方法
const RouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return RouterPush.call(this, to).catch(err => err)
}

//使用replace的方法
const RouterReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace (to) {
  return RouterReplace.call(this, to).catch(err => err)
}

export default router
